import "./IconLink.css";
import { Component } from "inferno";

export default class IconLink extends Component {
  moveTo(url) {
    return () => {
      window.location.href = url;
    };
  }

  render() {
    return (
      <div className={"social-block social-block-" + this.props.name}>
        <span
          onClick={this.moveTo(this.props.url)}
          className={"icon icon-" + this.props.name}
        ></span>
      </div>
    );
  }
}

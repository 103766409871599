import { Component } from "inferno";
import LazyImage from "./components/LazyImage/LazyImage";
import IconLink from "./components/IconLink/IconLink";
import "./App.css";

import JoostSource from "./assets/images/joost.webp";
import JoostPlaceholder from "./assets/images/joost.svg";

class App extends Component {
  render() {
    return (
      <div className="app">
        <header className="app-header">
          <div className="signature">
            <div className="title">Joost Lawerman</div>
            <div className="subtitle">Software Developer</div>
          </div>
          <LazyImage
            image={JoostSource}
            className="me-image"
            placeholder={JoostPlaceholder}
          />
        </header>
        {/* <content className="app-content">Hoi</content> */}
        <footer className="app-footer">
          <div className="signature">
            <div className="title"></div>
            <div class="subtitle">Follow me</div>
          </div>
          <div className="social-grid">
            <IconLink
              name="instagram"
              url="https://www.instagram.com/joostjoh/"
            />
            <IconLink
              name="linkedin"
              url="https://www.linkedin.com/in/joostlawerman/"
            />
            <IconLink name="github" url="https://github.com/joostlawerman/" />
            <IconLink name="twitter" url="https://twitter.com/JoostJoh" />
          </div>
        </footer>
      </div>
    );
  }
}

export default App;

import { Component, linkEvent } from "inferno";
import "./LazyImage.css";

export default class LazyImage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
    };
  }

  onLoad(instance) {
    setTimeout(() => {
      instance.setState({
        loaded: true,
      });
    }, 500 + Math.random() * 1500);
  }

  render() {
    return (
      <div className={"image-wrapper " + this.props.className}>
        <img
          alt="Joost"
          src={this.props.placeholder}
          className={"placeholder " + (this.state.loaded ? "transparant" : "")}
        />
        <img
          alt="Joost"
          onLoad={linkEvent(this, this.onLoad)}
          src={this.props.image}
        />
      </div>
    );
  }
}
